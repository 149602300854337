<template>
    <div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top:25px !important;">
        <c-upload 
          :attachInfo="attachInfo"
          :editable="false"
          :isPhotoView="true"
          label="피해정도 사진">
        </c-upload>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: 'accident-picture-TL',
    props: {
      param: {
        type: Object,
        default: () => ({
          iimAccidentId: '',
          stepCd: '',
        }),
      },
    },
    data() {
      return {
        editable: true,
        getUrl: '',
        attachInfo: {
          isSubmit: '',
          taskClassCd: 'PROCESS_DEGREE_DAMAGE',
          taskKey: '',
        },
      };
    },
    computed: {
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
      Object.assign(this.$data, this.$options.data());
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {
    },
    methods: {
      init() {
        // role setting
        // url setting
        this.$set(this.attachInfo, 'taskKey', this.param.iimAccidentId)
        // code setting
        // list setting
      },
    }
  };
  </script>
  