var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
        staticStyle: { "padding-top": "25px !important" },
      },
      [
        _c("c-upload", {
          attrs: {
            attachInfo: _vm.attachInfo,
            editable: false,
            isPhotoView: true,
            label: "피해정도 사진",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }